<template>
  <v-sheet style="max-width:100%;overflow-x:auto;overflow-y:hidden">
    <v-row style="min-width:840px;margin:0">
      <template v-for="i in 6">
        <v-col v-if="calendarShown[i]" :key="'cal-' + i" :cols="i === 1 ? 1 : ''" :style="'min-width:' + (i === 1 ? '50' : '150') + 'px'" :class="'pr-0' + (i > 1 ? ' pl-0' : '')">
          <v-calendar :ref="calendar[i]" v-model="calendarDay[i]" :now="today" :events="events" :class="i > 1 ? 'hideTimes' : 'onlyTimes'" mode="stack" color="primary" type="day" first-interval="30" interval-minutes="20" interval-count="15" @click:event="clickEvent"></v-calendar>
        </v-col>
      </template>
    </v-row>
  </v-sheet>
</template>
<style>
.v-calendar-daily__scroll-area {
  overflow-y: hidden;
}
div.v-calendar.hideTimes div.v-calendar-daily__intervals-head, div.v-calendar.hideTimes div.v-calendar-daily__intervals-body {
  display: none;
}
div.v-calendar.onlyTimes div.v-calendar-daily__intervals-head {
  height: 79.5px;
  width: 100% !important;
}
div.v-calendar.onlyTimes div.v-calendar-daily__intervals-head::after {
  content: '10:00 AM';
  font-size: 10px;
  padding-right: 12px;
  text-align: right;
}
div.v-calendar.onlyTimes div.v-calendar-daily__intervals-body {
  width: 100% !important;
}
div.v-calendar.onlyTimes div.v-calendar-daily_head-day, div.v-calendar.onlyTimes div.v-calendar-daily__day {
  display: none;
}
.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;
}
.v-event-drag-bottom::after {
  display: none;
  position: absolute;
  left: 50%;
  height: 4px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  width: 16px;
  margin-left: -8px;
  opacity: 0.8;
  content: '';
}

.v-event-drag-bottom:hover::after {
  display: block;
}
</style>
<script>
import { ref, computed, watch } from '@vue/composition-api'
import { yearMonDayFormat } from '@/helpers/formatters'

export default {
  props: {
    term: {
      type: String,
      required: true
    },
    instructor: {
      type: String,
      required: true
    },
    accompanist: {
      type: String,
      required: true
    },
    longSlot: {
      type: Boolean,
      default: false
    },
    overrideEvent: Object
  },
  setup (props, { root, emit }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => root.$store.state.roles)
    const events = ref([])
    const showEvents = computed(() => {
      if ('name' in props.overrideEvent && props.overrideEvent.name !== '') return [props.overrideEvent]
      return events.value
    })

    const service = root.$feathers.service('student/music/jury-slot')
    watch([() => props.term, () => props.instructor, () => props.accompanist, () => props.longSlot], () => {
      loadSlots()
    })
    async function loadSlots () {
      events.value = []
      if (props.term === '' || props.instructor == null || props.instructor === '' || props.accompanist === '') return
      const query = {
        term: props.term,
        active: true,
        $or: [
          { student: null, 'avail.instr': props.instructor },
          { student: { $exists: false }, 'avail.instr': props.instructor }
        ],
        $sort: {
          start: 1
        },
        $limit: 0
      }
      if (props.accompanist !== 'No Accompanist') {
        query.$or[0]['avail.accomp'] = props.accompanist
        query.$or[1]['avail.accomp'] = props.accompanist
      }
      const { total } = await service.find({ query })
      if (total === 0) {
        alert('There are no open time slots that match the instructor/accompanist selection. Please choose a different accompanist or contact the Music Department for assistance.')
      } else {
        const tempEvents = []
        query.$limit = 50
        for (let i = 0; i < total; i += 50) {
          query.$skip = i
          query.$populate = 'student'
          const { data } = await service.find({ query })
          for (const { _id: id, start, end, student } of data) {
            let name = 'Open'
            let color = 'grey'
            if (student) {
              name = student.name.first + ' ' + student.name.last
              color = 'success'
            }
            const obj = {
              id,
              name,
              start: new Date(start),
              end: new Date(end),
              color,
              timed: true
            }
            tempEvents.push(obj)
          }
        }
        // If looking for long slots, only keep ones where the start time on the following event matches the end time of the current event
        // We are going to go through them backwards so we are not skipping ones (which will happen if we go forward and remove items from the events array)
        if (props.longSlot) {
          for (let i = 0; i < tempEvents.length; i++) {
            const end = new Date(tempEvents[i].end)
            if (i < tempEvents.length - 1) {
              const nextStart = new Date(tempEvents[i + 1].start)
              if (nextStart.getTime() === end.getTime()) {
                end.setMinutes(end.getMinutes() + 10)
                const temp = { ...tempEvents[i], end }
                events.value.push(temp)
              }
            }
          }
        } else {
          events.value = tempEvents
        }
      }
    }

    const calendar = ref([])
    const calendarDay = ref(['', '', '', '', '', '', ''])
    const calendarShown = ref([true, true, true, true, true, true, true])
    watch(() => props.term, async () => {
      const { data } = await root.$feathers.service('system/term').find({ query: { term: props.term } })
      if (data.length === 0) {
        alert('The term was not found in the system table. Please contact Technology Services for assistance.')
        return
      }
      // Find the dates that are set up in the jury system
      const query = { term: props.term, $sort: { start: 1 }, $limit: 0 }
      const { total } = await service.find({ query })
      // Find the reading days in the closures
      let dates = {}
      query.$limit = 50
      for (let i = 0; i < total; i += 50) {
        query.$skip = i
        query.$populate = 'student'
        const { data } = await service.find({ query })
        for (const row of data) {
          let dt = new Date(row.start)
          let [month, day, year] = dt.toLocaleDateString().split('/')
          if (parseInt(month) < 10) month = '0' + month
          if (parseInt(day) < 10) day = '0' + day
          let date = year + '-' + month + '-' + day
          if (!(date in dates)) dates[date] = true
        }
      }
      let index = 2
      console.log(dates)
      for (const date in dates) {
        calendarDay.value[index] = date
        if (index === 2) calendarDay.value[1] = date
        index++
      }
      if (index <= 6) {
        while (index <= 6) {
          calendarShown.value.splice(index, 1, false)
          index++
        }
      }
    })
    const dt = new Date()
    let [month, day, year] = dt.toLocaleDateString().split('/')
    if (month < '10') month = '0' + month
    if (day < '10') day = '0' + day
    const today = ref(year + '-' + month + '-' + day)

    async function clickEvent ({ nativeEvent, event }) {
      nativeEvent.stopPropagation()
      if (event.name.substring(0, 4) === 'Open') {
        emit('clickEvent', event)
      }
    }

    return {
      user,
      roles,
      events,
      showEvents,
      loadSlots,
      calendar,
      calendarDay,
      calendarShown,
      today,
      clickEvent,
      yearMonDayFormat
    }
  }
}
</script>
